import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Full from "./Helper/Full";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [parentId, setParentId] = useState();
  const [isSelectedFolder, setIsSelectedFolder] = useState([]);
  const [fileType, setFileType] = useState()

  const [downloadLinks, setDownloadLinks] = useState([]);
  const [downloadFolderName, setDownloadFolderName] = useState([]);
  const [superParentId, setSuperParentId] = useState()

  useEffect(() => {
    const currentPath = window.location.pathname;
    const baseUrl = window.location.origin;
    if (currentPath.includes("/index.php/s/")) {
      const code = currentPath.split("/index.php/s/")[1];
      const transformedLink = `${baseUrl}/q?public_link_code=${code}`;
      window.location.href = transformedLink;
    }
  }, []);

  return (
    <BrowserRouter>
      <Full
        parentId={parentId}
        setParentId={setParentId}
        isSelectedFolder={isSelectedFolder}
        setIsSelectedFolder={setIsSelectedFolder}
        downloadLinks={downloadLinks}
        setDownloadLinks={setDownloadLinks}
        downloadFolderName={downloadFolderName}
        setDownloadFolderName={setDownloadFolderName}
        fileType={fileType}
        setFileType={setFileType}
        superParentId={superParentId}
        setSuperParentId={setSuperParentId}
      />
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
